@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --bg-primary: #ffffff;
    --bg-secondary: #f8fafc;
    --nav-bg: #f1f5f9;
    --text-primary: #1a1a1a;
    --text-secondary: #2d3748;
    --accent-primary: #8b5cf6;
    --accent-secondary: #7c3aed;
    --badge-bg: #e2e8f0;
    --badge-text: #1e293b;
    --tab-active: #8b5cf6;
    --tab-inactive: #4a5568;
  }

  :root.dark {
    --bg-primary: #0f172a;
    --bg-secondary: #1e293b;
    --nav-bg: #1e293b;
    --text-primary: #ffffff;
    --text-secondary: #e2e8f0;
    --accent-primary: #a855f7;
    --accent-secondary: #9333ea;
    --badge-bg: #334155;
    --badge-text: #ffffff;
    --tab-active: #a855f7;
    --tab-inactive: #94a3b8;
  }

  body {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    background-image: 
      radial-gradient(circle at 25px 25px, var(--accent-primary) 2%, transparent 0%),
      radial-gradient(circle at 75px 75px, var(--accent-primary) 2%, transparent 0%);
    background-size: 100px 100px;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: repeat;
    background-blend-mode: normal;
    opacity: 1;
  }

  /* Background Patterns */
  .bg-grid-pattern {
    background-size: 50px 50px;
    background-image: linear-gradient(to right, var(--text-secondary) 1px, transparent 1px),
                    linear-gradient(to bottom, var(--text-secondary) 1px, transparent 1px);
  }
  
  /* Subtle polka dots for text areas */
  .polka-subtle::before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: 
      radial-gradient(circle at center, var(--accent-primary) 1.5px, transparent 1.5px),
      radial-gradient(circle at 15px 15px, var(--accent-secondary) 1px, transparent 1px);
    background-size: 30px 30px;
    background-position: center center;
    opacity: 0.08;
    z-index: 0;
    pointer-events: none;
  }

  .polka-subtle.light-mode::before {
    opacity: 0.06;
    background-image: 
      radial-gradient(circle at center, var(--accent-secondary) 1.5px, transparent 1.5px),
      radial-gradient(circle at 15px 15px, var(--accent-primary) 1px, transparent 1px);
  }

  .dot-pattern {
    position: relative;
  }

  .dot-pattern::before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: radial-gradient(var(--accent-primary) 1px, transparent 1px);
    background-size: 20px 20px;
    background-position: center center;
    background-repeat: repeat;
    opacity: 0.15;
    mask-image: linear-gradient(to bottom, transparent, black 10%, black 90%, transparent);
  }

  .wave-pattern {
    position: relative;
  }

  .wave-pattern::before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%238b5cf6' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-position: center center;
    opacity: 0.2;
    mask-image: linear-gradient(to bottom, transparent, black 10%, black 90%, transparent);
  }

  .circuit-pattern {
    position: relative;
  }

  .circuit-pattern::before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%238b5cf6' fill-opacity='0.1'%3E%3Cpath d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position: center center;
    opacity: 0.2;
    mask-image: linear-gradient(to bottom, transparent, black 10%, black 90%, transparent);
  }

  .hexagon-pattern {
    position: relative;
  }

  .hexagon-pattern::before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='40' viewBox='0 0 24 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 40c5.523 0 10-4.477 10-10 0 5.523 4.477 10 10 10s10-4.477 10-10c0 5.523 4.477 10 10 10v-10c-5.523 0-10-4.477-10-10s4.477-10 10-10V0c-5.523 0-10 4.477-10 10 0-5.523-4.477-10-10-10S0 4.477 0 10c0-5.523-4.477-10-10-10v10c5.523 0 10 4.477 10 10s-4.477 10-10 10v10z' fill='%238b5cf6' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-position: center center;
    opacity: 0.2;
    mask-image: linear-gradient(to bottom, transparent, black 10%, black 90%, transparent);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.perspective {
  perspective: 1000px;
}
.preserve-3d {
  transform-style: preserve-3d;
}
.backface-hidden {
  backface-visibility: hidden;
}
.rotate-y-180 {
  transform: rotateY(180deg);
}
.scene {
  width: 400px;
  height: 200px;
  perspective: 600px;
  margin-bottom: 0px;
  
}

@media (min-width: 1000px) {
  .scene {
    height: 400px;
    margin-top: 100px;
  }
}
.cube {
  width: 400px;
  height: 400px;
  position: relative;
  transform-style: preserve-3d;
  animation: rotate 15s infinite linear;
}
.cube__face {
  position: absolute;
  width: 400px;
  height: 400px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(128, 90, 213, 0.1);
  backdrop-filter: blur(5px);
  color: white;
  font-size: 1rem;
  text-align: center;
}
.cube__face--front {
  transform: translateZ(200px);
}
.cube__face--right {
  transform: rotateY(90deg) translateZ(200px);
}
.cube__face--back {
  transform: rotateY(180deg) translateZ(200px);
}
.cube__face--left {
  transform: rotateY(-90deg) translateZ(200px);
}
.cube__face--top {
  transform: rotateX(90deg) translateZ(200px);
}
.cube__face--bottom {
  transform: rotateX(-90deg) translateZ(200px);
}
@keyframes rotate {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
}
@media (max-width: 768px) {
  .scene {
    width: 300px;
    height: 350px;
  }
  .cube {
    width: 300px;
    height: 300px;
  }
  .cube__face {
    width: 300px;
    height: 300px;
  }
  .cube__face--front {
    transform: translateZ(150px);
  }
  .cube__face--right {
    transform: rotateY(90deg) translateZ(150px);
  }
  .cube__face--back {
    transform: rotateY(180deg) translateZ(150px);
  }
  .cube__face--left {
    transform: rotateY(-90deg) translateZ(150px);
  }
  .cube__face--top {
    transform: rotateX(90deg) translateZ(150px);
  }
  .cube__face--bottom {
    transform: rotateX(-90deg) translateZ(150px);
  }
}

/* Blackhole Warp Pattern */
.blackhole-warp {
  position: relative;
}
.blackhole-warp::before {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(ellipse at center, transparent 0%, black 80%);
  animation: warp 10s infinite linear;
  opacity: 0.15;
  pointer-events: none;
  z-index: -1;
}
@keyframes warp {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1.2) rotate(360deg); }
}

/* Polka dot pattern */
.polka-pattern {
  position: relative;
}

.polka-pattern::before {
  content: '';
  position: absolute;
  inset: 0;
  background-image: 
    radial-gradient(circle at center, var(--accent-primary) 2px, transparent 2px),
    radial-gradient(circle at 20px 20px, var(--accent-secondary) 1px, transparent 1px);
  background-size: 40px 40px;
  background-position: center center;
  opacity: 0.1;
  z-index: 0;
  pointer-events: none;
}

.polka-pattern.light-mode::before {
  opacity: 0.08;
  background-image: 
    radial-gradient(circle at center, var(--accent-secondary) 2px, transparent 2px),
    radial-gradient(circle at 20px 20px, var(--accent-primary) 1px, transparent 1px);
}





/* Form styles */
.contact-form input,
.contact-form textarea {
  background-color: var(--bg-primary);
  border: 1px solid var(--accent-primary);
  color: var(--text-primary);
  padding: 0.75rem;
  width: 100%;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: var(--accent-secondary);
  box-shadow: 0 0 0 3px var(--accent-primary)/20;
}

.contact-form label {
  color: var(--text-primary);
  font-weight: 500;
}

/* Contact section background */
.contact-section {
  position: relative;
  background: linear-gradient(135deg, var(--bg-secondary), var(--bg-primary));
}

.contact-section::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(45deg, var(--accent-primary)/15, var(--accent-secondary)/15);
  z-index: 0;
}

.contact-bright {
  filter: brightness(1.1);
}
